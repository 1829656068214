import React from "react";
import { PageProps } from "gatsby";

interface ImageSitemapProps extends PageProps {
  pageContext: {
    images: Array<{
      loc: string;
      lastmod: string;
      title: string;
      caption: string;
      imageAlt: string;
    }>;
  };
}

const ImageSitemap: React.FC<ImageSitemapProps> = ({ pageContext }) => {
  const { images } = pageContext;

  const escapeXml = (unsafe: string): string => {
    return unsafe.replace(/[<>&'"]/g, (c) => {
      switch (c) {
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case "&":
          return "&amp;";
        case "'":
          return "&apos;";
        case '"':
          return "&quot;";
        default:
          return c;
      }
    });
  };

  const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
        xmlns:image="http://www.google.com/schemas/sitemap-image/1.1">
  ${images
    .map(
      (image) => `
    <url>
      <loc>${escapeXml(image.loc)}</loc>
      <lastmod>${escapeXml(image.lastmod)}</lastmod>
      <image:image>
        <image:loc>${escapeXml(image.loc)}</image:loc>
        ${
          image.title
            ? `<image:title>${escapeXml(image.title)}</image:title>`
            : ""
        }
        ${
          image.caption
            ? `<image:caption>${escapeXml(image.caption)}</image:caption>`
            : ""
        }
        ${
          image.imageAlt
            ? `<image:title>${escapeXml(image.imageAlt)}</image:title>`
            : ""
        }
      </image:image>
    </url>
  `
    )
    .join("")}
</urlset>
`;

  // Important: Set the content type to XML
  if (typeof window !== "undefined") {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(sitemap, "text/xml");
    console.log(xmlDoc);
  }

  return (
    <div>
      <pre>{sitemap}</pre>
    </div>
  );
};

export const Head = () => (
  <>
    <title>Image Sitemap</title>
    <meta httpEquiv="Content-Type" content="text/xml; charset=utf-8" />
  </>
);

export default ImageSitemap;
